/**
 * @generated SignedSource<<31e0c1d0e3a19a12c3b056a6002f45de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type CreateTestUserInput = {
  firstName: string;
  lastName: string;
  membershipPlanId: string;
  organizationMemberGroupIds?: ReadonlyArray<string> | null;
  products?: ReadonlyArray<CreateTestUserProductInput> | null;
};
export type CreateTestUserProductInput = {
  productId: string;
  role: ProductRole;
  memberGroupIds?: ReadonlyArray<string> | null;
};
export type CreateTestUserPageMutation$variables = {
  input: CreateTestUserInput;
};
export type CreateTestUserPageMutation$data = {
  readonly response: {
    readonly node: {
      readonly key: string;
      readonly testUser: {
        readonly fullName: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type CreateTestUserPageMutation = {
  variables: CreateTestUserPageMutation$variables;
  response: CreateTestUserPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTestUserPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateTestUserResponse",
        "kind": "LinkedField",
        "name": "createTestUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLink",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "testUser",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTestUserPageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateTestUserResponse",
        "kind": "LinkedField",
        "name": "createTestUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLink",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "testUser",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "781b28c55c1816ec6752f2b7716b84bb",
    "id": null,
    "metadata": {},
    "name": "CreateTestUserPageMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTestUserPageMutation(\n  $input: CreateTestUserInput!\n) {\n  response: createTestUser(input: $input) {\n    node {\n      key\n      testUser {\n        fullName\n        id\n      }\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8b7c317aee7e21378cfd2764eda7e3f";

export default node;
