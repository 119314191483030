/**
 * @generated SignedSource<<64743ebf59e02cfdb945ba2130ded0c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MemberGroupKind = "default" | "custom" | "role" | "test_user" | "%future added value";
export type MemberGroupRole = "owner" | "admin" | "manager" | "instructor" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditorMentionsProviderFragment$data = {
  readonly id: string;
  readonly mentions: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly id: string;
          readonly fullName: string;
        } | null;
        readonly memberGroup: {
          readonly id: string;
          readonly name: string;
          readonly kind: MemberGroupKind;
          readonly role: MemberGroupRole | null;
        } | null;
        readonly mentionedProduct: {
          readonly id: string;
          readonly name: string;
        } | null;
        readonly mentionedContentUsage: {
          readonly id: string;
          readonly content: {
            readonly id: string;
            readonly name: string | null;
          };
        } | null;
        readonly mentionedOccurrence: {
          readonly id: string;
          readonly name: string | null;
          readonly content: {
            readonly name: string | null;
          };
        } | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "EditorMentionsProviderFragment";
};
export type EditorMentionsProviderFragment$key = {
  readonly " $data"?: EditorMentionsProviderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditorMentionsProviderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditorMentionsProviderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentMentionNodeConnection",
      "kind": "LinkedField",
      "name": "mentions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentMentionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentMention",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberGroup",
                  "kind": "LinkedField",
                  "name": "memberGroup",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "mentionedProduct",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "mentionedContentUsage",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Occurrence",
                  "kind": "LinkedField",
                  "name": "mentionedOccurrence",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};
})();

(node as any).hash = "3d7a99048a584d8ce62af6bdd89d7def";

export default node;
