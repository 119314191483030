/**
 * @generated SignedSource<<0bcc29a092f491f055ebc3800c75cf5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type CreateTestUserInput = {
  firstName: string;
  lastName: string;
  membershipPlanId: string;
  organizationMemberGroupIds?: ReadonlyArray<string> | null;
  products?: ReadonlyArray<CreateTestUserProductInput> | null;
};
export type CreateTestUserProductInput = {
  productId: string;
  role: ProductRole;
  memberGroupIds?: ReadonlyArray<string> | null;
};
export type CreateTestUserModalMutation$variables = {
  input: CreateTestUserInput;
};
export type CreateTestUserModalMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type CreateTestUserModalMutation = {
  variables: CreateTestUserModalMutation$variables;
  response: CreateTestUserModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTestUserResponse",
    "kind": "LinkedField",
    "name": "createTestUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTestUserModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTestUserModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "53e77afb496fbe8c4cf104bda18660f5",
    "id": null,
    "metadata": {},
    "name": "CreateTestUserModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTestUserModalMutation(\n  $input: CreateTestUserInput!\n) {\n  response: createTestUser(input: $input) {\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "723cbed179f1343b01867ff87d563e0c";

export default node;
